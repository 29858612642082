<template>
  <list-container-widget
    id="about"
    :title="widgetName"
    :app-id="72"
    stretch
    class="d-flex"
  >
    <template #actions>
      <!-- Panel actions -->
      <widget-actions
        item-type="events-repository"
        :show-add="canAdd"
        :show-list="true"
        :show-filter="false"
      />
    </template>

    <!-- Loading -->
    <div v-if="isLoading">
      <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
    </div>
    <!-- Container with data -->
    <div v-else-if="items.length > 0">
      <div ref="container" class="x-scroll-container" @scroll="onScroll">
        <!-- Items Loop -->
        <div v-for="(item, index) in items" :key="index" :class="item.isPublished || (!item.isPublished && canAdd)?'x-scroll-element mx-1 mb-3 p-0':'d-none'">
          <event-item v-if="item.isPublished || (!item.isPublished && canAdd)" :item="item" />

          <!-- <b-link @click="eventLocation(item)">
            <div class="top">
              <div class="item-img text-center">
                <b-img
                  :alt="item.title"
                  :src="getImageSrc(item.backgroundURL) || placeholder"
                  rounded
                  fluid
                  class="ticket-image"
                />
              </div>
              <div class="title text-dark">
                {{ item.title }}
                <span class="text-muted font-weight-normal text-ellipsis" v-html="item.description" />
              </div>
            </div>
            <div class="rip" />
            <div class="bottom">
              <template v-if="item.startDate">
                {{ item.startDate | moment('DD/MM/YYYY HH:mm') }}
                <p v-if="item.endDate" class="mt-50">
                  {{ item.endDate | moment('DD/MM/YYYY HH:mm') }}
                </p>
              </template>
              <template v-else>
                <span class="text-secondary"> {{ $t('events.event-details.no-dates-yet') }} </span>
              </template>
            </div>
          </b-link> -->
        </div>
        <!-- Buttons prev and next -->
        <b-button
          variant="primary"
          class="link-previous btn btn-icon btn-primary btn-previous"
          @click="scrollOnePage(-1)"
        >
          <b-spinner small class="spinner" />
        </b-button>
        <b-button
          variant="primary"
          class="link-next btn btn-icon btn-primary btn-next"
          :class="{ 'btn-next--loading': isLoadingNextPage }"
          @click="scrollOnePage(+1)"
        >
          <b-spinner small class="spinner" />
        </b-button>
      </div>
    </div>
    <!-- Container without data: Placeholder -->
    <b-row v-else class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="eventsPlaceholder">
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t('available.message', { itemName: $t('events.title') }) }}
        </p>
      </b-col>
    </b-row>
  </list-container-widget>
</template>

<script>
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import BackgroundPlaceholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import EventsPlaceholder from '@/assets/images/placeholders/light/events.svg';
import { getImageResource } from '@/@core/utils/image-utils';
import { checkPermissions } from '@/@core/utils/roles-utils';
import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import EventItem from '@/views/apps/membershipEvents/components/EventItem.vue';

export default {
  name: 'CommunityEventsListWidget',
  components: {
    ListContainerWidget,
    WidgetActions,
    EventItem,
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      memberForMeeting: {},
      isRequestMeetingVisible: false,
    };
  },
  computed: {
    // Load data from store
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    itemsData() {
      return this.$store.getters.communityEvent;
    },
    items() {
      return this.itemsData.unpaginated;
    },
    currentCollectiveSlug() {
      return this.$store.getters.currentCollective.slug;
    },
    placeholder() {
      return BackgroundPlaceholder;
    },
    eventsPlaceholder() {
      return EventsPlaceholder;
    },
    canAdd() {
      return checkPermissions('create', 'eventRepository', this.loggedMemberRoles, this.currentCollective);
    },
    canList() {
      return checkPermissions('view', 'eventRepository', this.loggedMemberRoles, this.currentCollective);
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData(true);
    this.isLoading = false;
  },
  methods: {
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchData(force = false) {
      await this.$store.dispatch('getItems', {
        itemType: 'communityEvent',
        page: this.lastLoadedPage,
        perPage: 16,
        forceAPICall: force,
        requestConfig: {
          pastEvents: true,
        },
      });
      this.isLoading = false;
    },
    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd = event.srcElement.scrollWidth - event.srcElement.scrollLeft - event.srcElement.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    eventLocation(event) {
      window.location.href = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}',
        event.subdomain || 'app',
      ).replace('{slug}', event.slug);
    },
  },
};
</script>
<style lang="scss" scoped>
// Ticket can not have float with x-scroll-element class
.ticket {
  float: none;
  height: 300px !important;
}
.ticket .bottom {
  height: 50px;
}
.ticket .top {
  height: 216px;
  .ticket-image {
    height: 146px !important;
    object-fit: cover !important;
  }
}
</style>
